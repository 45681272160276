import React from "react";
import { Link } from "react-router-dom";
import Facebook from "../../assets/imgs/facebook.png";
import Instagram from "../../assets/imgs/instagram.png";
import Linkedin from "../../assets/imgs/linkedin.png";
import Youtube from "../../assets/imgs/youtube.png";

const Footer = () => {
  return (
    <div className="w-full bg-footer text-secondary">
      <div className="border-b border-white">
        <div className="max-w-[1200px] mx-auto grid grid-cols-12 py-[50px]">
          <div className="lg:col-span-4 md:col-span-6 col-span-12 px-[15px]">
            {/* <h4 className="mb-[26px] font-bold text-xl text-white">About Us</h4>
            <p className="font-normal text-sm leading-[26px] mb-8 text-justify">
              ಶಿವಸಂಗಮ ಸಮೂಹ, 2008ರಲ್ಲಿ ಆರಂಭ; ಆರ್ಥಿಕ ಸಮೃದ್ಧಿಯ ಮನೋಭಾವ, ಸಮಾಜ ಸ್ಥಾಯಿತೆಗಾಗಿ ಪ್ರಯತ್ನಿಸುತ್ತದೆ.
            </p> */}
            <div className="flex">
              <a href="#" target="_blank" className="flex justify-center items-center w-[38px] h-[38px] mx-[5px] border border-white rounded-full">
                <img src={Facebook} alt="facebook" className="h-[23px] invert -ml-[2px]" />
              </a>
              <a href="#" target="_blank" className="flex justify-center items-center w-[38px] h-[38px] mx-[5px] border border-white rounded-full">
                <img src={Instagram} alt="instagram" className="h-[23px] invert" />
              </a>
              <a href="#" target="_blank" className="flex justify-center items-center w-[38px] h-[38px] mx-[5px] border border-white rounded-full">
                <img src={Linkedin} alt="linkedin" className="h-[23px] invert -mt-1" />
              </a>
              <a href="#" target="_blank" className="flex justify-center items-center w-[38px] h-[38px] mx-[5px] border border-white rounded-full">
                <img src={Youtube} alt="youtube" className="h-[23px] invert" />
              </a>
            </div>
          </div>
          <div className="lg:col-span-3 md:col-span-6 col-span-12 px-[15px] md:px-20">
            <h4 className="mb-[26px] font-bold text-xl text-white">Links</h4>
            <ul>
              <li className="mb-[7px]">
                <Link className="font-normal text-base hover:text-white" to="/">Home</Link>
              </li>
              <li className="mb-[7px]">
                <Link className="font-normal text-base hover:text-white" to="/about">About Company</Link>
              </li>
              <li className="mb-[7px]">
                <Link className="font-normal text-base hover:text-white" to="/plans">Chit Plans</Link>
              </li>
              <li className="mb-[7px]">
                <Link className="font-normal text-base hover:text-white" to="/services">Services</Link>
              </li>
              <li>
                {/* <Link className="font-normal text-base hover:text-white" to="/how-it-works">How it Works</Link> */}
              </li>
            </ul>
          </div>
          <div className="lg:col-span-2 md:col-span-6 col-span-12 px-[15px]">
            <h4 className="mb-[26px] font-bold text-xl text-white">QUICK LINKS</h4>
            <ul>
              <li className="mb-[7px]">
                {/* <Link className="font-normal text-base hover:text-white" to="/certificate">Certificate</Link> */}
              </li>
              <li className="mb-[7px]">
                <Link className="font-normal text-base hover:text-white" to="/branches">Branches</Link>
              </li>
              <li className="mb-[7px]">
                {/* <Link className="font-normal text-base hover:text-white" to="/career">Career</Link> */}
              </li>
              <li>
                <Link className="font-normal text-base hover:text-white" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="lg:col-span-3 md:col-span-6 col-span-12 px-[15px]">
            <h4 className="mb-[26px] font-bold text-xl text-white">Contact Us</h4>
            <ul>
              <li className="mb-[19px]">
                <p className="font-normal text-base hover:text-white" to="/">Tontadarya Complex, Near Bhoomaraddi Circle, GADAG</p>
              </li>
              <li className="mb-[19px]">
                <Link className="font-normal text-base hover:text-white" to="tel:080-23571795"> 08372-233951 | 9742781777 </Link>
              </li>
              <li>
                <Link className="font-normal text-base hover:text-white" to="mailto:info@mykcpl.com">group@shivasangam.in</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div className="max-w-[1200px] mx-auto flex flex-col md:flex-row justify-between py-[31px]">
          <span className="font-normal text-sm text-center">© 2024 Shivasangam Groups. All rights reserved.</span>
          <span className="font-normal text-base text-right mr-5 md:mr-0">
            <span className="text-[#888888]">Designed by – </span>
            <Link to="#">SHIVAI Infotech</Link>
          </span>
        </div>
      </div>
    </div>
  );
};
export default Footer;