import React from "react";
import Breadcrumb from "../components/common/Breadcrumb";

const Plans = () => {
  return (
    <>
      <Breadcrumb label="Chit Plans" />
      <div className="max-w-[1200px] mx-auto py-[50px]">
        <table className="w-2/3 mx-auto">
          <thead>
            <tr className="text-white">
              <th className="p-[10px] border border-[#dddddd] bg-[#024180]">CHIT AMOUNT</th>
              <th className="p-[10px] border border-[#dddddd] bg-[#024180]">MONTHS</th>
              <th className="p-[10px] border border-[#dddddd] bg-[#024180]">MONTHLY RS.</th>
              <th className="p-[10px] border border-[#dddddd] bg-[#024180]"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center">1,50,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">30</td>
              <td className="p-[10px] border border-[#dddddd] text-center">5000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 2,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50</td>
              <td className="p-[10px] border border-[#dddddd] text-center">4000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 2,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">40</td>
              <td className="p-[10px] border border-[#dddddd] text-center">5,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 3,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">30 </td>
              <td className="p-[10px] border border-[#dddddd] text-center">10,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center">  5,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">40 </td>
              <td className="p-[10px] border border-[#dddddd] text-center">12,500</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 5,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50</td>
              <td className="p-[10px] border border-[#dddddd] text-center">10,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 6,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">30</td>
              <td className="p-[10px] border border-[#dddddd] text-center">20,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 10,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">40</td>
              <td className="p-[10px] border border-[#dddddd] text-center">25,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 10,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50</td>
              <td className="p-[10px] border border-[#dddddd] text-center">20,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 20,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50</td>
              <td className="p-[10px] border border-[#dddddd] text-center">40,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center"> 20,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">40</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>
            <tr className="even:bg-[#eee]">
              <td className="p-[10px] border border-[#dddddd] text-center">25,00,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50</td>
              <td className="p-[10px] border border-[#dddddd] text-center">50,000</td>
              <td className="p-[10px] border border-[#dddddd] text-center">
                <span className="px-5 py-[10px] bg-[#024180] text-white rounded-[20px] text-sm">Enroll Now</span>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </>
  );
};
export default Plans;