import React from "react";
// import { Link } from "react-router-dom";
import Company from "../assets/imgs/AboutComp.jpeg";
// import PhoneCall from "../assets/imgs/phone-call.png";
// import Mission from "../assets/imgs/mission.jpg";
// import Mission1 from "../assets/imgs/mission1.jpg";
// import Values from "../assets/imgs/values.png";
import Breadcrumb from "../components/common/Breadcrumb";

const About = () => {
  return (
    <>
      <Breadcrumb label="ABOUT SHIVASANGAM CHITS PVT. LTD." />
      <div className="relative py-[100px]">
        <div className="absolute bg-linear w-[182px] h-[107px] bg-no-repeat left-[100px] top-[50px] animate-linear"></div>
        <div className="flex max-w-[1200px] mx-auto px-[15px]">
          <div className="w-full md:w-full lg:w-1/2 px-[15px]">
            <h4 className="font-semibold text-[17px] text-[#f1592a] mb-[25px]">ABOUT US</h4>
            <div className="font-normal text-sm text-[#333333] leading-[26px] text-justify mb-[25px]">
              <p>
                ಸುಭದ್ರ ಮತ್ತು ಸುರಕ್ಷಿತ ಅರ್ಥ ವ್ಯವಸ್ಥೆಯ ಕಲ್ಪನೆ ಇಟ್ಟುಕೊಂಡು, ಸಮಾನ ಮನಸ್ಕ ಆಧ್ಯಾತ್ಮ ಜೀವಿಗಳೊಂದಿಗೆ 2008ರ ಅಕ್ಟೋಬರ್ 20 ರಂದು ನಿಮ್ಮ ಯೋಜನೆ ನಮ್ಮ ಆಸರೆ ಶೀರ್ಷಿಕೆ ಅಡಿಯಲ್ಲಿ ಶಿವಸಂಗಮ ಸಮೂಹ ಸಂಸ್ಥೆಯನ್ನು ಆರಂಭ ಮಾಡಿತು. ಚಿಟ್ಸ್ ಮತ್ತು ಫೈನಾನ್ಸ್ ವ್ಯವಹಾರದಿಂದ ಆರಂಭಗೊಂಡು, ಸೌಹಾರ್ದ ಸಹಕಾರಿ ಸಂಘವನ್ನು ಸ್ಥಾಪಿಸುವ ಮೂಲಕ ಬ್ಯಾಂಕಿನ ವ್ಯವಸ್ಥೆಯನ್ನು ಗ್ರಾಹಕ ಸದಸ್ಯರುಗಳಿಗೆ ಕಲ್ಪಿಸಿಕೊಟ್ಟಿದ್ದು, ಕೃಷಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ರೈತರ ಹಿತದೃಷ್ಠಿಯಿಂದ ಅವರ ಆರ್ಥಿಕ ಸಬಲತೆ ವಿಚಾರವನ್ನು ಹೊತ್ತು, ಹೈನುಗಾರಿಕೆ ಉದ್ಯೋಗ ಮಾಡುತ್ತ ಅದರೊಂದಿಗೆ ಹಾಲಿನ ಸಂಸ್ಕರಣ ಘಟಕವನ್ನು ಸ್ಥಾಪಿಸಿತು. ಆರ್ಥಿಕತೆಯ ಜೊತೆಗೆ ಸಮಾಜದಲ್ಲಿ ಸ್ವಾಸತೆಯನ್ನು ಗಮನದಲ್ಲಿಟ್ಟುಕೊಂಡು ಶುದ್ಧ ನೀರಿನ ಘಟಕವನ್ನು ಸ್ಥಾಪಿಸಿತು. ಹೀಗೆ ಎಲ್ಲಾ ವ್ಯವಹಾರಗಳನ್ನು ಪ್ರಾಮಾಣಿಕ ಮತ್ತು ಪಾರದರ್ಶಕವಾಗಿ ಮುನ್ನಡೆಸಿಕೊಂಡು ಸಾಗಿದೆ.
                ಕರ್ನಾಟಕದಾದ್ಯಂತ ತನ್ನ ಶಾಖೆಗಳನ್ನು ವಿಸ್ತರಿಸಿಕೊಂಡು ನಿರುದ್ಯೋಗ ಸಮಸ್ಯೆ ನಿವಾರಣೆಯ ದಿಟ್ಟ ಹೆಜ್ಜೆ ಎಂಬಂತೆ ನೂರಾರು ಯುವಕರಿಗೆ ಉದ್ಯೋಗಾವಕಾಶ ಕಲ್ಪಿಸಲಾಗಿದೆ. ಇಲ್ಲಿ ದುಡಿಯುವ ಯುವಕರು ಕೇವಲ ಉದ್ಯೋಗಿಗಳಾಗಿರದೇ, ಪ್ರಾಮಾಣಿಕ ಪ್ರತಿಬಿಂಬದ ಕಾಯಕ ನಿಷ್ಠೆ ಸೇನಾನಿಗಳಾಗಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವರು. ಸಹಸ್ರಾರು ಗ್ರಾಹಕರ ಪಾಲಿಗೆ ಕಾಮಧೇನು ಕಲ್ಪವೃಕ್ಷವಾಗಿ ಆರ್ಥಿಕ ಭದ್ರತೆ ಮತ್ತು ಅಪರಿಮಿತ ಸೇವೆಗಾಗಿ ಹತ್ತಾರು ಯೋಜನೆಗಳನ್ನು ರೂಪಿಸಿಕೊಂಡು ಅಭಿವೃದ್ಧಿ ಪಥದತ್ತ ಸಾಗಿದೆ. ನಿರಂತರ ಸಮಾಜಮುಖಿ ಕಾರ್ಯ ಮಾಡಲೆಂದೇ ಶಿವಸಂಗಮ ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ ಸ್ಥಾಪಿಸಿ, ಜನಾನುರಾಗಿ, ಜನೋಪಯೋಗಿ ಸೇವಾ ಕಾರ್ಯವನ್ನು ನಿರಂತರ ನಡೆಸುತ್ತಾ ರಕ್ತದಾನ, ನೇತ್ರದಾನ, ಧನ ಸಹಾಯದಂತಹ ಕ್ರಿಯಾಶೀಲ ಕಾರ್ಯಗಳು ಶಿವಸಂಗಮದ ಆತ್ಮಚೈತನ್ಯವಾಗಿದೆ. ಇದೆಲ್ಲವನ್ನು ಕಂಡು ಗದುಗಿನ ಚೇಂಬರ್ ಆಫ್ ಕಾಮರ್ಸ್ ಸಂಸ್ಥೆಯು 2022-23ನೇ ಸಾಲಿನ ಶ್ರೇಷ್ಠ ಉದ್ಯಮ ಪ್ರಶಸ್ತಿಯನ್ನು ನೀಡಿ ಗೌರವಿಸಿದೆ.
              </p>
            </div>
          </div>
          <div className="w-full md:w-full lg:w-1/2 px-[15px]">
            <div className="relative pl-[50px] pb-[50px] ml-[30px]">
              <div className="absolute bg-square-dot -top-[60px] -right-[55px] w-[176px] h-[183px] bg-no-repeat animate-zoom-fade"></div>
              <div className="absolute bg-square-dot top-[120px] left-[15px] w-[176px] h-[183px] bg-no-repeat animate-zoom-fade"></div>
              <img src={Company} alt="company" className="w-full rounded-[10px]" />
              {/* <div className="absolute left-0 bottom-0 px-5 py-2 w-[250px] bg-[#e35712] rounded-[10px]">
                <img src={PhoneCall} alt="phone-call" className="h-10 invert mb-[15px]" />
                <h4 className="font-semibold text-base text-[#f5cfc4] mb-[6px]">For Emergency</h4>
                <Link to="tel:1800-103-0794" className="font-medium text-[26px] text-white "> 08372-233951</Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="max-w-[1200px] mx-auto py-[50px] px-[15px] text-white">
        <div className="flex">
          <div className="w-1/2 p-[35px] bg-[#6f0801]">
            <h5 className="font-semibold text-xl">Our Mission</h5>
            <p className="font-normal text-sm leading-[26px]">
              We aim to build habit of saving among thousands of small businesses. In order to work towards this aim we want to collect chit fund amounts from our subscribers at their doorsteps everyday at zero cost.
            </p>
          </div>
          <div className="w-1/2">
            <img src={Mission} alt="mission" className="w-[36%] mx-auto" />
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2">
            <img src={Mission1} alt="mission1" className="w-[36%] mx-auto" />
          </div>
          <div className="w-1/2 p-[35px] bg-[#ff6b01]">
            <h5 className="font-semibold text-xl">Our Vision</h5>
            <p className="font-normal text-sm leading-[26px]">
              Our company vision is simple and straight-forward. We want to provide uncomplicated financial aid to ordinary individuals.
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 p-[35px] bg-[#2b95a3]">
            <h5 className="font-semibold text-xl">Our Values</h5>
            <p className="font-normal text-sm leading-[26px]">
              Commitment: We keep up our promises and are totally dedicated to our vast clientele.
            </p>
            <p className="font-normal text-sm leading-[26px]">
              Customer-focused: We provide quality products and services as per the needs of our customers.
            </p>
            <p className="font-normal text-sm leading-[26px]">
              Respect for Each Other: We highly value and respect our clients’ individuality, varied background and talent.
            </p>
            <p className="font-normal text-sm leading-[26px]">
              Winning with Integrity: We aim to become industry leaders by consistently delivering exceptional results to our clients
            </p>
          </div>
          <div className="w-1/2">
            <img src={Values} alt="values" className="w-[36%] mx-auto" />
          </div>
        </div>
      </div> */}
    </>
  );
};
export default About;