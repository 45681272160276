import React from "react";
import Breadcrumb from "../components/common/Breadcrumb";
import SquareService from "../components/common/SquareService";
import Service1 from "../assets/imgs/man9.png";
import Service2 from "../assets/imgs/man8.png"
import Service3 from "../assets/imgs/man6.png"
import Service4 from "../assets/imgs/man7.png"
// import Service5 from "../assets/imgs/service5.jpg"
// import Service6 from "../assets/imgs/service6.jpeg"
// import Service7 from "../assets/imgs/service7.jpg"
// import Service8 from "../assets/imgs/service8.jpg"
// import Service9 from "../assets/imgs/service9.jpg"

const Services = () => {
  return (
    <>
      <Breadcrumb label="MANAGEMENT" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-[30px] gap-y-[15px] max-w-[1200px] mx-auto px-[15px] py-[50px]">
        <SquareService imgUrl={Service1} header="Mr. Prashanth Desaimath" content="text..." />
        <SquareService imgUrl={Service2} header="Mr. Gavisiddappa Palled" content="text..." />
        <SquareService imgUrl={Service3} header="Name1" content="text more..." />
        <SquareService imgUrl={Service4} header="Name2" content="text more..." />
        {/* <SquareService imgUrl={Service5} header="UPI Collection" content="Another payment alternative is UPI Collection. With this option, subscribers interested in making payments via UPI will receive a payment link. They can conveniently use UPI payment apps like PhonePe, Google Pay, BHIM, and more to complete their transactions." />
        <SquareService imgUrl={Service6} header="Payment Gateway in customer app" content="We've integrated the payment options directly into our customer app, enabling customers to make payments conveniently within the company app itself." />
        <SquareService imgUrl={Service7} header="Auction via video conference" content="Customers will receive a Zoom App link to join the auctions. By logging in through the Zoom app, they can actively take part in the auctions, with the highest bidder earning the prized subscriber status." />
        <SquareService imgUrl={Service8} header="E-Passbook" content="Within the customer app, you'll find an E-passbook feature that holds all the details of their transactions." />
        <SquareService imgUrl={Service9} header="Instant Message" content="The company will send messages to the customer for every transaction, including bid rates and auction dates." /> */}
      </div>
    </>
  );
};
export default Services;

// import React from "react";
// import Breadcrumb from "../components/common/Breadcrumb";
// import Man1 from "../assets/imgs/man1.jpg";

// const Management = () => {
//   return (
//     <>
//       <Breadcrumb label="Management" />
//       <div className="max-w-[1200px] mx-auto py-[50px] px-[15px]">
//         <div className="flex px-[10px] py-[15px] bg-[#F5FAFF]">
//           <div className="w-1/3 px-[15px]">
//             <br />
//             <br />
//             <img src={Man1} alt="man1" className="max-w-full" />
//           </div>
//           <div className="w-2/3 px-[15px]">
//             <h1 className="font-semibold text-[25px]">Mr. Parashuram K N</h1>
//             <h4 className="font-medium text-base text-[#333333]">Managing Director of KCPL</h4>
//             <br />
//             <p className="font-normal text-sm text-[#333333] leading-[26px]">Mr. Parashuram K N, a seasoned professional boasting over two decades of extensive experience in IT, administration, and project management,
//             has played a pivotal and instrumental role in steering the success and robust growth of Kodachadri Chits Pvt Ltd.</p>
//             <p className="font-normal text-sm text-[#333333] leading-[26px]">Originating from the city of Shivamogga, Mr. Parashruram K N has consistently led from the forefront since assuming the role of Managing Director.
//             Under his astute leadership, Kodachadri Chit Funds Private Limited is firmly on track to ascend as the premier Chit fund company in India. His approach is
//             deeply customer-centric, marked by effective technological integration, allowing customers to invest, monitor their balances, and execute transactions
//             seamlessly via the mobile app from the comfort of their homes.</p>
//             <p className="font-normal text-sm text-[#333333] leading-[26px]">In addition to revolutionizing the customer experience, Mr. Parashruram K N has ushered in a renewed sense of credibility within the chit-fund industry.
//             He has harnessed the power of digital technologies to deliver faster solutions and seamless experiences to offer the best chit-funds in India. </p>
//             <p className="font-normal text-sm text-[#333333] leading-[26px]">While steering the company towards its vision of becoming the top Chit fund company in India, Mr. Parashruram K N has remained unwavering in his commitment
//             to enhancing business productivity and elevating profitability for the organization. </p>
//             <p className="font-normal text-sm text-[#333333] leading-[26px]">He has been driving business productivity and improved profitability for the company while driving towards the vision of becoming the No.1 Chit fund company in India. </p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Management;